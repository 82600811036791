<template>
  <v-row align="start" class="h-100" no-gutters>
    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" lg="2">
      <kurcc-app-bar-navigation-drawer-items/>
    </v-col>
    <v-col cols="12" lg="10">
      <v-card class="pe-6" tile>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="auto">
            <v-card-title>{{ pageTitle }}</v-card-title>
          </v-col>
          <v-col class="pe-3">
            <kurcc-profile-action-buttons v-if="isDashboardProfilePage" :clone="profileComponent.cloneSite"
                                          :loading="profileComponent.updatingSite" :save="profileComponent.updateSite"/>
          </v-col>
        </v-row>
      </v-card>
      <v-container class="fill-height" fluid>
        <router-view @ref="profileComponent = $event"/>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KurccDashboardPage',
  data () {
    return {
      profileComponent: {}
    }
  },
  components: {
    KurccAppBarNavigationDrawerItems: () => import('@/modules/app/components/KurccAppBarNavigationDrawerItems'),
    KurccProfileActionButtons: () => import('@/modules/dashboard/components/KurccProfileActionButtons')
  },
  computed: {
    pageTitle () {
      return this.$lodash.upperFirst(this.$route.name)
    },
    isDashboardProfilePage () {
      return this.$route.name === 'profile'
    }
  }
}
</script>
